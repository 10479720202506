import type { TextByYears } from '~/features/Home/types'

export const AWARD_DATE_TEXTS: TextByYears = {
  2023: '1 ноября 2023, 18:00 Мск',
  2024: '2 ноября 2024',
}

export const GRAND_PRIX_DATE_TEXTS: TextByYears = {
  2022: '19 ноября 2022, 17:00 Мск',
  2023: '18 ноября 2023, 17:30 Мск',
  2024: '16 ноября 2024',
}

export const GRAND_PRIX_PREVIEW_TEXTS: TextByYears = {
  2023: `
    Определяем номинантов на звание лучших
    врачей всей страны и лучших клиник каждого
    округа, Москвы и Санкт-Петербурга.
    Встречаемся на МедРокет days’23, чтобы
    назвать победителей и вручить награды.
  `,
  2024: `
    Определим номинантов на звание лучших
    врачей всей страны и лучших клиник каждого
    округа, Москвы и Санкт-Петербурга.
    Встречаемся на конференции «Дни МедРокет 2024», чтобы
    назвать победителей и вручить награды.
  `,
}

export const GRAND_PRIX_CURRENT_YEAR_STRING_CONSTS = {
  broadcastLink: 'https://www.youtube.com/watch?v=wvMBjwNG6A4',
  reminder: {
    title: 'Результаты появятся после церемонии награждения',
    text: 'Назовём лучших врачей страны и лучшие клиники каждого округа, Москвы и Санкт-Петербурга. Ставьте напоминание, чтобы первыми узнать имена победителей.',
    buttonText: 'Установить напоминание',
    subText: 'Начало трансляции 18 ноября 2023, 17:30 Мск',
  },
  broadcast: {
    buttonText: 'Смотреть трансляцию',
    buttonIcon: 'ui-icon-player-play',
    subText: 'Результаты опубликуем 19 ноября в 10:00 Мск',
  },
}
